<template>
  <div
    class="modal"
    style="z-index: 1005 !important;"
     v-bind:class="{ 'is-active': showEditModal }"
  >
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Edit Zone</p>
        <button class="delete" aria-label="close" @click="toggleClose"></button>
      </header>
      <section class="modal-card-body">
        <table class="table is-fullwidth">
          <tbody>
            <tr>
              <td>
                <div class="field">
                  <label class="label">Name </label>

                  <input type="text" v-model="editSelectedZone.value" />
                </div>
              </td>
              <td>
                <div class="field">
                  <label class="label"> Min KM </label>
                  <input type="number" min="0" v-model="editSelectedZone.min" placeholder="Min KM" />
                </div>
              </td>
              <td>
                <div class="field">
                  <label class="label">Max KM </label>
                  <input type="number" min="0" v-model="editSelectedZone.max" placeholder="Max KM" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <footer class="modal-card-foot is-justify-content-flex-end">
        <button
          @click="editZone"
          v-bind:class="
            isEditing ? editButtonLoading : editButtonNotLoading
          "
        >
          Edit
        </button>
        <button
          class="button is-small level-item is-rounded"
          @click="toggleClose"
        >
          Close
        </button>
      </footer>
    </div>
  </div>
</template>
<script>
import { zonesInfoCollection } from '@/store/fireStore'
import store from '../store'

const environment = store.state.environment

export default {
  data () {
    return {
      isEditing: false,
      editButtonLoading:
        'button is-small is-info level-item is-rounded is-loading',
      editButtonNotLoading:
        'button is-small is-info level-item is-rounded',
      editSelectedZone: {
        value: '',
        min: '',
        max: ''
      }
    }
  },
  props: {
    showEditModal: {
      type: Boolean
    },
    selectedZone: {
      type: Object
    }
  },
  methods: {
    toggleClose () {
      this.$emit('closeModal')
    },
    toggleRender () {
      this.$emit('renderMap')
    },
    async editZone () {
      this.isEditing = true
      this.editSelectedZone.key = this.editSelectedZone.value
      try {
        zonesInfoCollection
          .doc(this.editSelectedZone.id)
          .update({
            key: this.editSelectedZone.key,
            value: this.editSelectedZone.value,
            max: this.editSelectedZone.max,
            min: this.editSelectedZone.min,
            selected: this.editSelectedZone.selected,
            environment
          })
          .then(() => {
            this.isEditing = false
            this.$emit('renderMap')
            this.toggleClose()
            this.$notify({
              type: 'success',
              group: 'zones',
              title: 'Zone Edit Successfully'
            })
          })
      } catch (e) {
        this.isEditing = false
        this.$notify({
          type: 'error',
          group: 'zones',
          title: 'There was an error, please try again'
        })
        console.log(e)
        throw e
      }
    }
  },
  mounted () {
    console.log('this.selectedZone:', this.selectedZone)
    this.editSelectedZone = Object.assign({}, this.selectedZone)
  }
}
</script>
<style>
</style>
